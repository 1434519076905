import React from "react";

import Box from "@mui/material/Box";
import { Container, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import RssFeedRoundedIcon from "@mui/icons-material/RssFeedRounded";
import Chip from '@mui/material/Chip';

function Footer() {
  const theme = createTheme({
    typography: {
      footer: {
        fontWeight: 500,
        fontSize: 16,
        fontStyle: "Josefin Sans",
        color: "#FFFFFF",
      },
    },
  });

  const routeChange = () =>{ 
    window.location.replace('https://api.ao-litija.si/media/rss.xml')

  }
  return (
    <Container className="m-0 p-0" maxWidth={false}>
      <Box
        className="m-0"
        display={"flex"}
        alignItems={"center"}
        sx={{
          height: 80,
          backgroundColor: "#1f4154",
          justifyContent: "space-around",
        }}
      >
        <Chip icon={<RssFeedRoundedIcon color="white" />} label="RSS" onClick={routeChange} sx={{backgroundColor: "#ff7c33", color: "white"}}/>
        <ThemeProvider theme={theme}>
          <Typography variant="footer">
            ©2023 Alpinistični odsek Litija
          </Typography>
        </ThemeProvider>
        <Box />
      </Box>
    </Container>
  );
}

export default Footer;
